<template>
  <div class="supplier">
    <!-- 当前店铺 -->
    <div class="current-shop">
      当前店铺：
      <el-select
        v-model="queryInfo.merchantId"
        placeholder="请选择店铺"
        clearable=""
        @clear="getSupplierList"
        @change="getSupplierList"
      >
        <el-option
          v-for="item in shopList"
          :key="item.id"
          :label="item.merchantsInfo.shopName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 卡片视图区域 -->
    <el-card>
      <div class="top-btn">
        <div>
          <el-button
            type="primary"
            @click="showAddSupplierDialog"
          >+ 新增供货商</el-button>
        </div>
        <div style="margin-left:20px;">
          <el-button
            class="download"
            type="primary"
          ><a
              style="color:#FFFFFF;"
              href="http://file.agricultural.maruiwhu.cn/file/upload/null/2022-02-18/bdb6054a80ac40b6ab47d221ecd6472a.xlsx"
            >下载模板</a></el-button>
        </div>
        <div style="margin-left:20px;">
          <el-upload
            ref="upload"
            :action="uploadUrl"
            :limit="1"
            :before-upload="beforeUpload"
            accept=".xlsx"
            class="up-class"
            :on-success="uploadSuccess"
            :show-file-list="false"
            :auto-upload="true"
            :headers="headers"
          >
            <el-button type="primary">导入</el-button>
          </el-upload>
        </div>
      </div>
      <!-- 第二行 -->
      <div class="secondrow">
        <!-- 根据供货商查询 -->

        <div class="input">
          供货商：
          <el-input
            placeholder="请输入供货商"
            v-model="queryInfo.supplierName"
            clearable
            @clear="getSupplierList"
            @change="searchMerchant"
          >
            <el-button
              slot="append"
              @click="searchMerchant"
            >搜索</el-button>
          </el-input>
        </div>

        <!-- 根据联系人查询 -->

        <div class="input">
          联系人：
          <el-input
            placeholder="请输入联系人"
            v-model="queryInfo.contact"
            clearable
            @clear="getSupplierList"
            @change="searchMerchant"
          >
            <el-button
              slot="append"
              @click="searchMerchant"
            >搜索</el-button>
          </el-input>
        </div>

        <!-- 计价方式 -->

        <div class="input">
          联系方式：
          <el-input
            placeholder="请输入联系方式"
            v-model="queryInfo.mobile"
            clearable
            @clear="getSupplierList"
            @change="searchMerchant"
          >
            <el-button
              slot="append"
              @click="searchMerchant"
            >搜索</el-button>
          </el-input>
        </div>
      </div>
      <!-- 内容主体 -->
      <el-table
        :data="supplierList"
        stripe
        style="width: 100%"
        border
      >
        <!-- 第一列供货商id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 店铺名称 -->
        <el-table-column
          label="店铺名称"
          aligin="center"
          prop="merchantsVo.merchantsInfo.shopName"
        >
        </el-table-column>
        <!-- 第二列供货商名称 -->
        <el-table-column
          label="供货商名称"
          aligin="center"
          prop="supplierName"
        >
        </el-table-column>
        <!-- 第三列联系人 -->
        <el-table-column
          label="联系人"
          aligin="center"
          prop="contact"
        >
        </el-table-column>
        <!-- 第四列联系方式 -->
        <el-table-column
          label="联系方式"
          width="100"
          aligin="center"
          prop="mobile"
        >
        </el-table-column>
        <!-- 第五列默认 -->
        <el-table-column
          label="默认地址"
          aligin="center"
          prop="isDefault"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.isDefault === 1"
              type="primary"
            >默认</el-tag>
            <el-tag
              v-else
              type="info"
            >不默认</el-tag>
          </template>
        </el-table-column>
        <!-- 第四列详细地址 -->
        <el-table-column
          label="详细地址"
          width="250"
          aligin="center"
          prop="address"
        >
        </el-table-column>
        <el-table-column
          label="星级"
          aligin="center"
          prop="starLevel"
        >
        </el-table-column>
        <!-- 第六列操作 -->
        <el-table-column
          label="操作"
          width="200"
          aligin="center"
        >
          <!-- 修改按钮 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="showEditSupplierDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeSupplierById(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加供货商对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addSupplierDialogVisible"
      width="50%"
      @close="clearAddSupplier"
    >
      <el-form
        :model="merchantSupplierForm"
        :rules="supplierRules"
        ref="supplierFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="店铺名称"
          prop="merchantId"
        >
          <el-select
            v-model="merchantSupplierForm.merchantId"
            placeholder="请选择店铺"
            autocomplete="off"
            @change="selectMerchant"
            class="select"
            :disabled="isShow"
          >
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.merchantsInfo.shopName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="供货商名称"
          prop="supplierName"
        >
          <el-input
            v-model="merchantSupplierForm.supplierName"
            placeholder="请输入供货商名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          prop="contact"
        >
          <el-input
            placeholder="请输入联系人"
            v-model="merchantSupplierForm.contact"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="mobile"
        >
          <el-input
            placeholder="请输入联系方式"
            v-model="merchantSupplierForm.mobile"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="星级"
          prop="starLevel"
        >
          <el-rate
            class="star"
            v-model="merchantSupplierForm.starLevel"
            :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
          >
          </el-rate>
        </el-form-item>
        <el-form-item
          label="省市区"
          prop="selectedOptions"
        >
          <el-cascader
            size="large"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="详细地址"
          prop="address"
        >
          <el-input
            placeholder="请输入详细地址"
            v-model="merchantSupplierForm.address"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否默认"
          prop="isDefault"
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="merchantSupplierForm.isDefault"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addSupplierDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  data () {
    // 手机号校验规则
    var checkMobile = (rule, value, callback) => {
      // 手机正则表达式
      const regMobile = /^(0|86|17951)?(1[0-9])[0-9][0-9]{8}$/
      if (regMobile.test(value)) {
        // 合法的手机号
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    return {
      // 市场id
      marketId: '',
      // 店铺数组
      shopList: [],
      // 店铺请求入参
      shopQueryInfo: {
        limit: 2000,
        pageNo: 1,
        marketId: ''
      },
      // 查询供货商入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketId: '',
        merchantId: '',
        supplierName: '', // 供货商name
        mobile: '',
        contact: '' // 联系人
      },
      // 供货商列表
      supplierList: [],
      // 总数
      total: 0,
      // 添加供货商入参
      merchantSupplierForm: {
        supplierName: '',
        contact: '',
        mobile: '',
        isDefault: undefined,
        merchantId: '',
        marketId: '',
        provinceId: undefined,
        cityId: undefined,
        districtId: undefined,
        address: '',
        starLevel: null
      },
      options: regionData,
      selectedOptions: [],
      // 新增对话框，默认false
      addSupplierDialogVisible: false,
      // 新增和修改公用一个对话框
      title: '',
      submitType: '',
      // 校验规则
      supplierRules: {
        merchantId: [
          { required: true, message: '请选择店铺', trigger: 'blur' }
        ],
        supplierName: [
          { required: true, message: '请输入供货商名称', trigger: 'blur' }
        ],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      // 修改所需要的项目id
      supplierId: '',
      // 是否禁用店铺选择
      isShow: true,
      uploadUrl: '', // 地址
      headers: {
        'admin-authorization': ''
      }
    }
  },
  created () {
    // 获取商户列表
    this.getMerchantList()
    // 取上级路由传送过来的id
    this.queryInfo.merchantId = this.$route.query.id
    // 调用列表接口
    this.getSupplierList()
    this.headers['admin-authorization'] = window.sessionStorage.getItem('token')
  },
  mounted () {
    this.marketId = window.sessionStorage.getItem('currentMarketId')
    this.uploadUrl = `/api/v1/admin/merchantSupplier/import?marketId=${this.marketId}`
  },
  methods: {
    searchMerchant () {
      this.queryInfo.pageNo = 1
      this.getSupplierList()
    },
    // 调用商户列表方法
    async getMerchantList () {
      // 赋值marketId
      this.shopQueryInfo.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get('merchants/list', {
        params: this.shopQueryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看商户失败')
      }
      this.shopList = res.data.data
    },
    // 调用供货商查询列表
    async getSupplierList () {
      // 赋值市场id
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('merchantSupplier/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看供货商列表失败')
      }
      this.supplierList = res.data.data
      this.total = res.data.total
    },
    // 省市区
    handleChange () {
      var loc = ''
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]]
      }
      console.log(loc)
      console.log(this.selectedOptions)
      // 赋值省市区,需要整型,还需要去掉尾数的0
      this.merchantSupplierForm.provinceId =
        parseInt(this.selectedOptions[0]) / 10000
      console.log(this.merchantSupplierForm.provinceId)
      this.merchantSupplierForm.cityId = parseInt(this.selectedOptions[1]) / 100
      console.log(this.merchantSupplierForm.cityId)
      this.merchantSupplierForm.districtId = parseInt(this.selectedOptions[2])
    },
    // 强制更新
    selectMerchant () {
      this.$forceUpdate()
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getSupplierList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getSupplierList()
    },
    // 打开添加供货商对话框
    showAddSupplierDialog () {
      this.title = '添加供货商'
      this.submitType = 'add'
      // 商户id直接赋值
      this.merchantSupplierForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      this.merchantSupplierForm.merchantId = this.queryInfo.merchantId
      this.isShow = false
      this.addSupplierDialogVisible = true
    },
    // 打开修改供货商对话框
    showEditSupplierDialog (row) {
      this.title = '修改供货商'
      this.submitType = 'edit'
      this.getMerchantList()
      this.merchantSupplierForm = row
      // 转为整型
      this.merchantSupplierForm.merchantId = parseInt(row.merchantId)
      this.supplierId = row.id
      // 判断是否有省市区
      if (row.locationVo) {
        // 将id取出来，成为数组
        this.selectedOptions = [
          row.locationVo.province.regionId * 10000 + '',
          row.locationVo.city.regionId * 100 + '',
          row.locationVo.county.regionId + ''
        ]
      } else {
      }
      this.isShow = true
      this.addSupplierDialogVisible = true
    },
    // 提交请求
    submit () {
      this.$refs.supplierFormRef.validate(async valid => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'merchantSupplier',
            this.merchantSupplierForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加供货商成功')
          this.getSupplierList()
          this.addSupplierDialogVisible = false
        } else {
          const { data: res } = await this.$http.put(
            `merchantSupplier/${this.supplierId}`,
            this.merchantSupplierForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改供货商成功')
          this.getSupplierList()
          this.addSupplierDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddSupplier () {
      this.merchantSupplierForm = {}
      this.selectedOptions = []
      this.$refs.supplierFormRef.resetFields()
      this.getSupplierList()
    },
    // 删除操作
    async removeSupplierById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该供货商，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`merchantSupplier/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除供货商失败')
      }
      this.$message.success('删除供货商成功')
      this.getSupplierList()
    },
    // 导入摊位
    beforeUpload (file) {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (extension !== 'xlsx') {
        this.$message({
          message: '上传文件只能是 xlsx格式!',
          type: 'warning'
        })
        return false
      }
    },
    uploadSuccess (res, file, fileList) {
      if (res.code !== 0) {
        this.$message({
          message: '导入失败!',
          type: 'error'
        })
      } else {
        this.$message({
          message: '导入成功!',
          type: 'success'
        })
      }
      this.$refs.upload.clearFiles()
      this.getSupplierList()
    }
  }
}
</script>

<style lang="less" scoped>
.top-btn {
  padding-bottom: 20px;
  display: flex;
}
.supplier {
  position: relative;
}
.current-shop {
  position: absolute;
  top: -60px;
  left: 353px;
}
.input {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  // margin-right: -20px;
}
.input:last-child {
  margin-left: 5px;
}
.el-select {
  width: 180px;
}
.el-input {
  width: 220px;
}
.secondrow {
  display: flex;
  width: 1266px;
  margin-left: -5px;
}
.el-row {
  margin-bottom: 20px;
}
.select {
  width: 220px;
}
.el-cascader {
  width: 220px;
}
.star {
  width: 200px;
  height: 50px;
  line-height: 50px;
  // border: 1px solid #000;
}
</style>
